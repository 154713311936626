.markdown code {
  display: block;
  white-space: pre-wrap;
}

.reactMarkDown {
  padding: 5px;
  color: black;
  font-family: Roboto, sans-serif;
  text-align: left;
  border-radius: 5px;
}

.reactMarkDown a {
  color: black;
  text-decoration: underline;
}

.reactMarkDown h1 {
  font-size: 32px;
}

.reactMarkDown h2 {
  font-size: 26px;
}

.reactMarkDown h3 {
  font-size: 22px;
}

.reactMarkDown h4 {
  font-size: 20px;
}

.reactMarkDown p {
  margin-top: 8px;
  margin-bottom: 8px;
  color: black;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  text-align: left;
}

.reactMarkdown div {
  color: white;
}

.reactMarkDown li {
  margin-left: 25px;
  color: black;
  text-align: left;
}

.carousel {
  height: 75%;
}

#swag-shop {
  padding-right: 30px;
  padding-left: 30px;
  overflow: visible;
}

@media only screen and (min-width: 401px) {
  .carousel .slider-wrapper {
    width: 55%;
  }
}

@media only screen and (max-width: 400px) {
  .carousel .slider-wrapper {
    width: 100%;
  }
}

#pointIndicator {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #F2C6DE;
  font-weight: bolder;
  font-size: 4rem;
  text-align: center;
}

#pointCategory {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #A9CFEE;
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
}
